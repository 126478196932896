import React from "react"
import Layout from "../../components/Layout/Layout"
import Container from "../../components/Layout/Container"
import ReferForm from "../../components/Intake/ReferForm"

export default () => (
  <Layout
    title="Get Started"
    subtitle="Fill out this intake form so we can prompt whoever needs a mental health teleconsult"
    seoTitle="Refer for Teleconsult"
    display={{ footer: false, helpCenterBanner: false }}
    isPrivate
  >
    <Container isCentered desktop={6} fullhd={6}>
      <ReferForm />
    </Container>
  </Layout>
)
