import React from "react"
import { Link } from "gatsby"
import FormCheckbox from "../../Elements/Form/FormCheckbox"

const ConsentAndAuthorization = ({ values }) => {
  return (
    <div className="notification py-2">
      <div className="content">
        <div className="columns is-mobile">
          <div className="column pb-0 pr-0 is-narrow">
            <FormCheckbox
              name="terms"
              values={values?.terms}
              options={[
                "I understand and agree to MedGrocer’s Terms and Conditions and Privacy Policy.",
              ]}
              hideOptions
            />
          </div>
          <div className="column px-0 pb-0">
            I understand and agree to MedGrocer’s{" "}
            <Link to="/terms-and-conditions">Terms and Conditions</Link> and{" "}
            <Link to="/privacy-policy">Privacy Policy</Link>.
          </div>
        </div>
        <div className="columns is-mobile">
          <div className="column pr-0 is-narrow">
            <FormCheckbox
              name="informedConsent"
              values={values?.informedConsent}
              options={[
                "I understand and consent to my rights and responsibilities, and those of mental health professionals, as outlined in the Informed Consent Form.",
              ]}
              hideOptions
            />
          </div>
          <div className="column px-0">
            I have secured the consent of the person I am referring prior to
            submitting this form; as such, MedGrocer can reach out to them at
            any time and update me on the status of their session. As the
            referrer, I agree to be held accountable for possible sanctions
            arising from false or malicious referrals.
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConsentAndAuthorization
