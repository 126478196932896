import React from "react"

import FormRadio from "../../Elements/Form/FormRadio"
import Section from "../../Elements/Section"

import { currentRoleChoices } from "../utils/formData"

const ReferrerPersonalInformation = ({ userData, values }) => {
  return (
    <Section title="Tell us about yourself" id="referrer-information">
      <FormRadio
        name="referrer.currentRole"
        title="Current Role"
        value={values?.referrer?.currentRole}
        options={currentRoleChoices}
        isRequired
      />
      <table className="table is-fullwidth">
        <tbody>
          <tr>
            <th>Name</th>
            <td>
              {userData?.firstName || ""} {userData?.lastName || ""}
            </td>
          </tr>
          <tr>
            <th>Mobile Number</th>
            <td>{userData?.mobileNumber || ""}</td>
          </tr>
          <tr>
            <th>Work Email</th>
            <td>{userData?.email || ""}</td>
          </tr>
        </tbody>
      </table>
    </Section>
  )
}

export default ReferrerPersonalInformation
