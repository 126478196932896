import React from "react"
import { GATSBY_CLIENT_EMAIL_DOMAIN } from "gatsby-env-variables"

import FormInput from "../Elements/Form/FormInput"
import FormDate from "../Elements/Form/FormDate"
import FormRadio from "../Elements/Form/FormRadio"
import FormSelect from "../Elements/Form/FormSelect"
import Section from "../Elements/Section"

import { genderChoices, sites } from "./utils/formData"

const ReferredEmployeeInformation = ({ path, values, errors, submitCount }) => {
  return (
    <Section
      id="employee-information"
      title={
        path === "self"
          ? "Tell us about yourself"
          : "Tell us who you’re referring for a teleconsult"
      }
    >
      <FormInput
        label="First Name"
        name="employee.firstName"
        placeholder="Juan"
        type="text"
        isRequired
      />
      <FormInput
        label="Last Name"
        name="employee.lastName"
        placeholder="dela Cruz"
        type="text"
        isRequired
      />
      <FormDate
        label="Birthday"
        name="employee.birthday"
        values={values?.employee.birthday}
        isRequired={path === "self"}
        errors={errors}
        submitCount={submitCount}
      />
      {path === "self" ? (
        <FormRadio
          name="employee.gender"
          options={genderChoices}
          title="Gender"
          value={values?.employee.gender}
          isRequired
        />
      ) : null}
      <FormInput
        label="Mobile Number"
        name="employee.mobileNumber"
        maxLength={11}
        pattern="[0,9]{2}[0-9]{9}"
        placeholder="09991234567"
        type="tel"
        isRequired
      />
      <FormInput
        label="Personal Email"
        placeholder="jdelacruz@email.com"
        name="employee.personalEmail"
        type="email"
        isRequired
      />
      <FormInput
        label="Work Email"
        name="employee.email"
        placeholder="juan.delacruz"
        type="text"
        isRequired
        hasAddons
        addonRight={
          <span className="button is-static">{GATSBY_CLIENT_EMAIL_DOMAIN}</span>
        }
      />
      <FormInput
        label="Employee Number"
        name="employee.employeeNumber"
        placeholder="1234567"
        type="text"
        isRequired={path === "self"}
      />
      <FormSelect
        label="Site"
        name="employee.site"
        placeholder="Select site below..."
        options={sites}
        value={values?.employee.site}
        isRequired
        errors={errors}
        submitCount={submitCount}
      />
      <FormInput
        label="Account/Department"
        name="employee.department"
        placeholder="DoorDash"
        type="text"
        // helper="e.g. DoorDash"
        isRequired
      />
      <FormInput
        label="Employment Start"
        helper={`When did ${
          path === "self" ? `you` : `they`
        }  start working at TaskUs?`}
        name="employee.employmentStart"
        placeholder="Jan 2020"
        type="text"
        isRequired={path === "self"}
      />
    </Section>
  )
}

export default ReferredEmployeeInformation
