export const intakeReferralEmailTemplate = (data, zendeskID) => {
  const { referrer, employee, reasonForReferral, riskAssessmentScore } = data

  const zendeskIDTemplate = zendeskID
    ? `<h3 style="text-align: center;">Teleconsult Ticket Number</h3><h3 style="text-align: center; background: #fadea4; padding: 1em; margin: auto; width: 20em;">${zendeskID}</h3><br/>`
    : ""

  const formattedBirthday = referrer?.birthday?.month
    ? `<tr><td>Birthday</td><td>${referrer.birthday.month.value} ${referrer.birthday.date.value} ${referrer.birthday.year}</td></tr>`
    : ``

  const formattedPersonalEmail = referrer?.personalEmail
    ? `<tr><td>Personal Email</td><td>${referrer.personalEmail}</td></tr>`
    : ``

  const formattedEmployeeNumber = employee?.employeeNumber
    ? `<tr><td>Employee Number</td><td>${employee.employeeNumber}</td></tr>`
    : ``

  return `
  <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <meta charset="utf-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <html lang="en">
    <head>
      <style>
        @import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
      </style>
      <style type="text/css">
      @media only screen and (max-width: 770px) {
        .logo {
          width: 100%;
        }
      }

      @media screen {
        .logo {
          width: 30%;
        }
      }

      body {
        font-family: 'Lato', sans-serif;
        line-height: 1.25;
      }

      .subtitle {
        margin-top: 2.25rem;
      }

      b {
        color: #0d6d6e;
      }

      .content {
        width: 95%;
        margin: auto;
      }

      @media only screen and (min-width: 770px) {
        .content {
          width: 60%;
        }
      }

      table {
        border-collapse: collapse;
        width: 100%;
      }

      table,
      th,
      td {
        border: 1px solid #ccc;
      }

      td {
        padding: 10px;
      }

      h3 {
        margin-top: 0.75rem;
        color: #0d6d6e;
      }

      .terms {
        padding: 10px;
        background: #eeeeee;
        font-size: 11px;
        color: #606060;
      }

      .has-text-right {
        text-align: right;
      }

      .terms th,
      .terms td {
        padding: 5px;
      }
      </style>
    </head>

    <body>
      <div class="container">
        <div class="content">
          <img
            src="https://s3-ap-southeast-1.amazonaws.com/data.medgrocer.com/public/logo/mgsolo_teal.png"
            class="logo"
            width="200"
          />

          <p class="subtitle">Hi <b>${referrer.firstName}</b>,</p>
          <p>
            Thank you for submitting your referral for a teleconsult. Our coordinator will email
            the employee you’ve referred with a proposed schedule within 24 hours.
          </p>

          ${zendeskIDTemplate}

          <p>
            Below is a copy of your referral. If you wish to correct
            anything, please send an email to
            <a
              href="mailto:wellness@medgrocer.com?Subject=Change%20Intake%20Details:%20Ticket%20#${zendeskID}"
              target="_blank"
              >wellness@medgrocer.com</a
            >
            with the subject line "Change
          Intake Details: Ticket #${
            zendeskID || "(Your Teleconsult Ticket Number)"
          }".
          </p>

          <p>If you believe this employee needs urgent help, please notify HR immediately.</p>

          <br />
          <p>
            Regards, <br />
            <strong>The MedGrocer Team</strong>
          </p>
          <br />
          <hr style="background-color: #ccc;" />
          <p class="subtitle is-primary">
            <b>Review Your Responses</b>
          </p>
          <br />

          <table class="table">
            <tbody>
              <tr>
                <td colspan="2"><b>Your Information</b></td>
              </tr>
              <tr>
                <td width="40%">Current Role</td>
                <td>${referrer.currentRole}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>${referrer.firstName} ${referrer.lastName}</td>
              </tr>
              ${formattedBirthday}
              <tr>
                <td>Mobile Number</td>
                <td>${referrer.mobileNumber}</td>
              </tr>
              <tr>
                <td>Work Email</td>
                <td>${referrer.email}</td>
              </tr>
              <tr>
                <td colspan="2"><b>Employee Information</b></td>
              </tr>
              <tr>
                <td width="40%">Name</td>
                <td>${employee.firstName} ${employee.lastName}</td>
              </tr>
              <tr>
                <td>Mobile Number</td>
                <td>${employee.mobileNumber}</td>
              </tr>
              ${formattedPersonalEmail}
              <tr>
                <td>Work Email</td>
                <td>${employee.email}</td>
              </tr>
              ${formattedEmployeeNumber}
              <tr>
                <td>Site</td>
                <td>${employee.site.label}</td>
              </tr>
              <tr>
                <td>Account/Department</td>
                <td>${employee.department}</td>
              </tr>
              <tr>
                <td>Current Work Hours</td>
                <td>${employee.currentWorkHours || "N/A"}</td>
              </tr>
              <tr>
                <td>Reason for Referral</td>
                <td>${reasonForReferral}</td>
              </tr>
              <tr>
                <td>Risk Assessment Score</td>
                <td>${riskAssessmentScore.value || "N/A"}</td>
              </tr>
            </tbody>
          </table>

          <br />
          <br />
          <div class="terms">
            <p>
              This email serves as confirmation that you:
            </p>

            <ul>
              <li>
                Understand and agree to MedGrocer’s
                <a
                  href="https://wellness.medgrocer.com/terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Terms and Conditions</a
                >
                and
                <a
                  href="https://wellness.medgrocer.com/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Privacy Policy</a
                >
              </li>
              <li>
                Have secured the consent of the person you are referring prior to
                submitting this form; as such, MedGrocer can reach out to them
                at any time and update you on the status of their session.
                As the referrer, you also agree to be held accountable for possible
                sanctions arising from false or malicious referrals.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </body>
  </html>
`
}
